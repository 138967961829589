import React, { useState, useEffect } from "react";
import { QRCodeSVG } from "qrcode.react";
import { CircularProgress } from "@mui/material";

const formatCryptoAddress = (cryptoType, address) => {
  switch (cryptoType.toLowerCase()) {
    case "btc":
      return `bitcoin:${address}`;

    default:
      return address;
  }
};
const walletImage = (cryptoType) => {
  switch (cryptoType.toLowerCase()) {
    case "btc":
      return `/assets/images/bitcoin.svg`;

    default:
      return "/eth.png";
  }
};

const CryptoQRCode = ({ cryptoType, address, size = 180 }) => {
  const [formattedAddress, setFormattedAddress] = useState(address);
  const [qrImage, setqrImage] = useState("");
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);

    const walletCompatibleAddress = formatCryptoAddress(cryptoType, address);
    const qrImg = walletImage(cryptoType);
    setqrImage(qrImg);
    setFormattedAddress(walletCompatibleAddress);
    setLoading(false);
  }, [cryptoType, address]);

  return (
    <div className="crypto-qr-code-container">
      {loading ? (
        <CircularProgress size={24} />
      ) : (
        <QRCodeSVG
          value={formattedAddress}
          size={size}
          level={"H"}
          minVersion={7}
          imageSettings={{
            src: qrImage,
            x: undefined,
            y: undefined,
            height: 24,
            width: 24,
            opacity: 0.8,
            excavate: true,
          }}
        />
      )}
    </div>
  );
};

export default CryptoQRCode;
