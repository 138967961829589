import { useState, useEffect } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Alert,
  AlertTitle,
  styled,
} from "@mui/material";

// Create a styled Dialog component with backdrop blur effect
const BlurredDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiBackdrop-root": {
    backdropFilter: "blur(8px)",
    backgroundColor: "rgba(0, 0, 0, 0.5)", // Semi-transparent background
  },
  "& .MuiDialog-paper": {
    borderRadius: "12px",
    boxShadow: "0 8px 32px rgba(0, 0, 0, 0.2)",
    background: "rgba(255, 255, 255, 0.95)", // Slightly transparent paper
    backdropFilter: "blur(4px)",
    border: "1px solid black",
  },
}));
const AnnouncementModal = ({ banner, descriptions }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    // Check if the banner exists and has active status
    if (banner?.status) {
      // Create a unique key for this announcement based on the title (or other unique identifier)
      const announcementKey = `announcement_viewed_${banner.title.replace(
        /\s+/g,
        "_"
      )}`;

      // Check if the user has viewed this announcement in the current session
      const hasViewedInCurrentSession = sessionStorage.getItem(announcementKey);

      if (!hasViewedInCurrentSession) {
        // User hasn't viewed this announcement in the current session, show it
        setOpen(true);
        // Mark as viewed for the current session only
        sessionStorage.setItem(announcementKey, "true");
      }
    }
  }, [banner]);

  const handleClose = () => {
    setOpen(false);
  };

  // If there's no banner or it's not active, don't render anything
  if (!banner?.status) {
    return null;
  }

  return (
    <BlurredDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="announcement-dialog-title"
      maxWidth="md"
      sx={{
        p: 0,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      fullWidth>
      <DialogContent sx={{ backgroundColor: "background.default", p: 0 }}>
        <Alert severity="success">
          <AlertTitle sx={{ fontSize: "18px", mb: 1 }}>
            {banner.title}
          </AlertTitle>
          {descriptions.map((line, index) => (
            <Typography key={index} sx={{ fontSize: "16px", mb: 2 }}>
              {index + 1}:- {line}
            </Typography>
          ))}
        </Alert>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "background.default" }}>
        <Button onClick={handleClose} color="primary" variant="contained">
          Close
        </Button>
      </DialogActions>
    </BlurredDialog>
  );
};

export default AnnouncementModal;
